<template>
    <CreateBasicComponent end-point="shelves"></CreateBasicComponent>
</template>

<script>
    import CreateBasicComponent from "@/components/views/CreateBasicComponent";

    export default {
        name: 'Shelf',
        components: {CreateBasicComponent},
    }
</script>
